import { useCallback, useState } from "react";
import { sendMessage } from "../../api";
import { MessageType, SendPayload } from "../../shared/types";
import styles from "./styles.module.css";
import { IS_LOADING } from "../../view/Chat";
import { useFetchParamsContext } from "../../../context/useFetchParamsContext";

export interface InputProps {
  messages: MessageType[];
  userEmail: string;
  chatId: string;
  token: string;
  setMessages: (newMessages: MessageType[]) => void;
  selectedTab: string;
}

export const Input: React.FC<InputProps> = ({
  userEmail,
  chatId,
  token,
  messages,
  setMessages,
  selectedTab,
}) => {
  const [input, setInput] = useState<string>("");
  const { fetchParams } = useFetchParamsContext();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!input.trim()) return;
      const userMessage = { role: "user", content: input };
      const assistantMessage = { role: "assistant", content: IS_LOADING };
      setMessages([...messages, userMessage, assistantMessage]);
      const payload: SendPayload = {
        chat_id: chatId,
        user_email: userEmail,
        message: input,
        current_tab: selectedTab,
      };
      setInput("");

      try {
        const urlParams = new URLSearchParams({
          organization: fetchParams.company.org_id || "",
        }).toString();
        const res = await sendMessage(payload, token, urlParams);
        if (res.status === 200) {
          setMessages([...messages, userMessage, res.data]);
        } else {
          const assistantMessage = { role: "assistant", content: "" };
          setMessages([...messages, userMessage, assistantMessage]);
        }
      } catch (e) {
        console.error("Error sending message:", e);
        const assistantMessage = {
          role: "assistant",
          content: "Something went wrong please try again later.",
        };
        setMessages([...messages, userMessage, assistantMessage]);
      }
    },
    [input, fetchParams.company]
  );

  return (
    <form className={styles["amigo-input-form"]} onSubmit={handleSubmit}>
      <input
        type="text"
        value={input}
        onChange={handleChange}
        placeholder="Type here..."
      />
      <button type="submit">Send</button>
    </form>
  );
};
