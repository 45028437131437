import { useState, useEffect, useRef, useMemo } from "react";
import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { PayrollInsights } from "@/types/data";
import { useFetchParamsContext } from "../context/useFetchParamsContext";

type insightsOptions = Array<PayrollInsights & { method: string }> | null;

const useGetInsights = (data: insightsOptions, employee: boolean) => {
  const [insights, setInsights] = useState<any>([]);
  const [trigger, setTrigger] = useState<boolean>(true);
  const prevDataRef = useRef<{
    data: insightsOptions | null;
    company: string;
  } | null>(null);
  const { user } = useUserContext();
  const { fetchParams } = useFetchParamsContext();

  async function requestInsights() {
    if (!user?.credential || employee) return;
    try {
      const params = new URLSearchParams({
        organization: fetchParams.company.org_id || "",
      }).toString();

      console.log(data);

      const bodyToSend = data?.map((params) => {
        const parameters = params.parameters;
        return {
          ...params,
          ["parameters"]: {
            ...parameters,
            ["organization"]: fetchParams.company.org_id,
          },
        };
      });
      const req = await fetch(`${API_BASE_URL}/llm/insights?${params}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.credential}`,
        },
        body: JSON.stringify(bodyToSend),
      });
      const reqData = await req.json();

      if (reqData) {
        setInsights(reqData);
        setTrigger(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /*this useEffect logic is as follows:
        We created a useRef for a data refrence which is null at first,
        if the data has not been initialized inside the useRef "prevDataRef" then it initializing a new one.
        if theres already a useRef data refrence then we compare it to the new Data if its different then run a request to the server.
        else only switch trigger state off.
        
        ##This logic was create the prevent unnecessary requests to the server when the parameters of the data didnt change.
        */
    const prevData = prevDataRef?.current?.data;
    const shouldFetch =
      !prevDataRef ||
      !prevDataRef.current ||
      JSON.stringify(prevData) !== JSON.stringify(data) ||
      prevDataRef.current.company !== fetchParams.company.org_id;
    if (shouldFetch && trigger && data) {
      prevDataRef.current = {
        data: data,
        company: fetchParams.company.org_id || "",
      };
      requestInsights();
    } else {
      setTrigger(false);
    }
  }, [trigger, data, fetchParams.company]);

  return { insights, trigger, setTrigger };
};

export default useGetInsights;
