import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Select,
  MenuItem,
  Box,
  Typography,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useGetPayrollDashboad } from "../../hooks/useGetPayrollDashboard";
import { formatKey, formatLargeNumber } from "../../utils/common";
import InsightsBtn from "../Common/buttons/InsightsBtn";

const PayrollDashboard: React.FC = () => {
  // State for the selected month and year
  const [params, setParams] = useState<{
    parameters: {
      month: number;
      year: number;
    };
    path: string;
    tab: string;
  }>({
    path: "payroll/dashboard",
    tab: "dashboards",
    parameters: {
      month: 9,
      year: 2024,
    },
  });

  let payrollDashboadData = useGetPayrollDashboad(
    params.parameters.month,
    params.parameters.year
  );
  let overview = payrollDashboadData.payrollDashboard?.payroll_overview ?? {};
  let payrollCost = payrollDashboadData?.payrollDashboard?.payroll_cost ?? [];
  let headCount = payrollDashboadData?.payrollDashboard?.head_count ?? [];

  return (
    <Grid container sx={{ display: "block" }}>
      {/* First Row: Select Components */}
      <Grid
        sx={{ border: "1px dashed", padding: "9px", "border-radius": "5px" }}
      >
        <Grid className="flex  gap-5">
          <FormControl>
            <InputLabel>Month</InputLabel>
            <Select
              value={params.parameters.month}
              onChange={(e) =>
                setParams((prev) => {
                  return {
                    ...prev,
                    parameters: {
                      ...prev.parameters,
                      month: Number(e.target.value),
                    },
                  };
                })
              }
              variant="outlined"
              sx={{ minWidth: 120, margin: "0px 10px 8px 0px" }}
            >
              {[...Array(12)].map((_, i) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>Year</InputLabel>
            <Select
              value={params.parameters.year}
              onChange={(e) =>
                setParams((prev) => {
                  return {
                    ...prev,
                    parameters: {
                      ...prev.parameters,
                      year: Number(e.target.value),
                    },
                  };
                })
              }
              variant="outlined"
              sx={{ minWidth: 120 }}
            >
              {[2022, 2023, 2024, 2025].map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InsightsBtn params={params} />
          </FormControl>
          <Box
            sx={{
              marginLeft: "25px",
              marginTop: "7px",
              display: "inline-block",
              justifyContent: "center",
            }}
          >
            {payrollDashboadData.loading ? <CircularProgress /> : ""}
            {payrollDashboadData.error ? payrollDashboadData.error : ""}
          </Box>
        </Grid>
        {/* Second Row: Boxes */}
        <Grid container spacing={4} marginBottom={2}>
          {Object.entries(overview ?? {}).map(([key, value], index) => (
            <Grid xs={12} sm={2.4} key={key}>
              <div className="border border-solid border-[#ccc] rounded-sm p-1 text-center bg-[#D2E2E3]">
                <h4 className="text-lg 2xl:text-2xl font-light font-sans">
                  {formatKey(key)}
                </h4>
                <h3 className="text-xl 2xl:text-3xl font-normal font-sans">
                  {formatLargeNumber(value)}
                </h3>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Third Row: Payroll Cost Chart */}
      <Grid marginBottom={2} marginTop={2}>
        <Typography variant="h4" gutterBottom>
          Payroll Cost
        </Typography>
        <ResponsiveContainer minWidth={500} width="100%" height={200}>
          <BarChart data={payrollCost}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis  style={{
              fontSize: '0.8rem',
              fontFamily: 'Arial',
            }}
                    tickFormatter={tick => {
                      return tick.toLocaleString();
                    }}/>
            <Tooltip formatter={(value:any) => new Intl.NumberFormat('en').format(value)} />
            <Legend />
            <Bar dataKey="R&D" stackId="a" fill="#82ca9d" />
            <Bar dataKey="SALES" stackId="a" fill="#ffc658" />
            <Bar dataKey="CS" stackId="a" fill="#8884d8" />
            <Bar dataKey="G&A" stackId="a" fill="#855B3B" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>

      {/* Fourth Row: Head Count Chart */}
      <Grid>
        <Typography variant="h4" gutterBottom>
          Head Count
        </Typography>
        <ResponsiveContainer minWidth={500} width="100%" height={200}>
          <BarChart data={headCount}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="R&D" stackId="a" fill="#82ca9d" />
            <Bar dataKey="SALES" stackId="a" fill="#ffc658" />
            <Bar dataKey="CS" stackId="a" fill="#8884d8" />
            <Bar dataKey="G&A" stackId="a" fill="#855B3B" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default PayrollDashboard;
