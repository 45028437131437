import React from 'react'

type Props = {
    children: React.ReactNode,
    minWidth?: Number, //In Percantage of screen width "vw"
    minHeight?: Number, //In Percantage of screen height "vh"
    setModal?:React.Dispatch<React.SetStateAction<boolean>>
}

const Modal = ({children, minWidth, minHeight}: Props) => {
  return (
    <section className='z-[99999] absolute top-0 left-0 w-full h-full flex justify-center items-center backdrop-brightness-75'>
        <div className={`p-5 shadow-2xl rounded-xl bg-white min-w-[40vw]] min-h-[${minHeight ? minHeight : 40}vh] max-w-[700px] relative`}>
            {children}
        </div>
    </section>
  )
}

export default Modal