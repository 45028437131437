import axios, { AxiosResponse } from "axios";
import { SendPayload } from "../shared/types";

const newApi = axios.create({
  baseURL:
    // "http://0.0.0.0:8001/llm",
    "https://8co2thh7d9.execute-api.us-east-1.amazonaws.com/llm",
});

export const sendMessage = async (
  payload: SendPayload,
  token: string,
  urlParams: string
): Promise<AxiosResponse> => {
  const payloadToSend = {
    msg: payload.message,
    current_tab: payload.current_tab,
  };
  return await newApi.get(`/chat?${urlParams}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: payloadToSend,
  });
};
