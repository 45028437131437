import { useReducer } from "react";
import { MessageType } from "./shared/types";

type ChatState = {
  messages: MessageType[];
  chat: string;
  user: string;
};

type ChatAction =
  | { type: "SET_MESSAGES"; payload: MessageType[] }
  | { type: "ADD_MESSAGE"; payload: MessageType }
  | { type: "SET_CHAT"; payload: string };

const chatReducer = (state: ChatState, action: ChatAction): ChatState => {
  switch (action.type) {
    case "SET_MESSAGES":
      return { ...state, messages: action.payload };
    case "ADD_MESSAGE":
      return { ...state, messages: [...state.messages, action.payload] };
    case "SET_CHAT":
      return { ...state, chat: action.payload };
    default:
      return state;
  }
};

const useChatReducer = (initialState: ChatState) => {
  return useReducer(chatReducer, initialState);
};

export { useChatReducer };
export type { ChatState, ChatAction, MessageType };
