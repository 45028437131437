import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Tooltip } from '@mui/material';

interface ExplanationProps {
    children: React.ReactNode
    explanation?: string
}

export const Explanation = ({ explanation, children }: ExplanationProps) => {
      return (
        <>
            <p><ReactMarkdown remarkPlugins={[remarkGfm]}>{`${children}`}</ReactMarkdown></p>
            {explanation && (
                <Tooltip title={explanation} slotProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: "gray",
                        },
                    },
                }}>
                    <HelpOutlineIcon fontSize="small" style={{ color: 'black' }} />
                </Tooltip>
            )}
        </>
    )
}