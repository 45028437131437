import React, { createContext, useContext, useState, useEffect } from "react";
import { API_BASE_URL } from "../types/constanst";
import { useUserContext } from "./user-context";

const AuthContext = createContext({
  isWhitelisted: null as boolean | null,
  loading: true,
  error: null as Error | null,
  userDetails: null as Error | null,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUserContext();
  const [currency, setCurrency] = useState<string>("");

  const [authData, setAuthData] = useState({
    isWhitelisted: null as boolean | null,
    loading: null as boolean | null,
    error: null as Error | null,
    userDetails: null as Error | null | Record<any, any>,
  });

  useEffect(() => {
    if (!user?.credential) {
      return;
    }

    const fetchWhitelistStatus = async () => {
      try {
        setAuthData((prev) => ({ ...prev, loading: true }));

        const response = await fetch(`${API_BASE_URL}/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.credential}`,
          },
        });
        setAuthData((prev) => ({ ...prev, loading: true }));

        if (response.ok) {
          const data = await response.json();
          setAuthData({
            isWhitelisted: true,
            loading: false,
            error: null,
            userDetails: data,
          });
        } else {
          setAuthData({
            isWhitelisted: false,
            loading: false,
            error: new Error("Failed to fetch whitelist status"),
            userDetails: null,
          });
        }
      } catch (error) {
        console.log("error", error);

        setAuthData({
          isWhitelisted: false,
          loading: false,
          error: error as Error,
          userDetails: null,
        });
      }
    };

    fetchWhitelistStatus();
  }, [user]);

  return (
    <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
  );
};

// Hook to consume the AuthContext
export const useAuth = () => useContext(AuthContext);
