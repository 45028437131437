import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CLIENT_ID } from "./types/constanst";
import { UserContextProvider } from "./context/user-context";
import { AuthProvider } from "./context/AuthContext";
import { FetchParamsProvider } from "./context/useFetchParamsContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <UserContextProvider>
        <AuthProvider>
          <FetchParamsProvider>
            <App />
          </FetchParamsProvider>
        </AuthProvider>
      </UserContextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
