import React from "react";
import { useTranslation } from "react-i18next";
import PayrollNav from "../Common/Payroll/PayrollNav/PayrollNav";
import PayrollDashboard from "../Payroll/PayrollDashboad";
import IrregularGrossTable from "../Payroll/IrregularGross";
import InputOutputTable from "../Payroll/InputOutput";
import AgreementTab from "../Payroll/AgreementTab";
import { useGetInputOutput } from "../../hooks/useGetInputOutput";
import { useSelectedTab } from "../../context/SelectedTabContext";

export const PayrollTabs: React.FC = () => {
  const { t } = useTranslation();
  const { selectedTab, setSelectedTab } = useSelectedTab();

  return (
    <>
      <PayrollNav setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      <div className="workspace_content px-3 xl:px-5 min-w-[100px] overflow-auto">
        {selectedTab === t("payroll.dashboard").toLowerCase() ? (
          <PayrollDashboard />
        ) : selectedTab === t("payroll.inputOutput").toLowerCase() ? (
          <InputOutputTable />
        ) : selectedTab === t("payroll.irregular").toLowerCase() ? (
          <IrregularGrossTable />
        ) : selectedTab === t("payroll.agreements").toLowerCase() ? (
          <AgreementTab />
        ) : (
          <PayrollDashboard />
        )}
      </div>
    </>
  );
};
