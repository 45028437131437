import { useFetchParamsContext } from "../context/useFetchParamsContext";
import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { InputOutput } from "../types/data";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";

const defaultInputOutput: InputOutput = {
  employees: [],
};

export const useGetInputOutput = (): { inputOutput: InputOutput } => {
  const [InputVsOutput, setInputVsOutput] =
    useState<InputOutput>(defaultInputOutput);
  const { user } = useUserContext();
  const { fetchParams } = useFetchParamsContext();

  const options = useMemo(
    () => ({
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${user?.credential}`,
      },
    }),
    [user?.credential]
  );

  const fetchInputOutput = useCallback(async () => {
    try {
      const params = new URLSearchParams({
        target_month: "2024/09",
        currency: fetchParams.currency,
        organization: fetchParams.company.org_id || "",
      }).toString();
      const response = await fetch(
        `${API_BASE_URL}/payroll/input-vs-output?${params}`,
        options
      );
      const data = await response.json();
      setInputVsOutput(data);
    } catch (error) {
      console.error("Error fetching input/output:", error);
    }
  }, [options, fetchParams]);

  // Ref to store whether the data has already been fetched
  // const hasFetchedRef = useRef(null);
  const previousParamsRef = useRef<{
    currency: string;
    company: string;
  } | null>(null);

  useEffect(() => {
    // Only fetch if we haven't fetched data before
    const shouldFetch =
      !previousParamsRef.current || // First time fetch
      previousParamsRef.current.currency !== fetchParams.currency || // Previous currency changed
      previousParamsRef?.current.company !== fetchParams.company.org_id; // Previous company changed

    if (shouldFetch) {
      fetchInputOutput();
      previousParamsRef.current = {
        currency: fetchParams.currency,
        company: fetchParams.company.org_id || "",
      };
    }
  }, [fetchInputOutput, fetchParams]); // Run effect only once based on the fetchInputOutput function

  return { inputOutput: InputVsOutput };
};
