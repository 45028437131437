import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Stack,
  Grid2,
  Collapse,
  CircularProgress,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useGetAgreements } from "../../hooks/useGetAgreement";
import {Agreement, Dictionary, PayrollInsights} from "@/types/data";
import { formatKey } from "../../utils/common";
import { UploadFile } from "../navbar/UPloadFIle";
import { useUserContext } from "../../context/user-context";
import {PublishedWithChanges} from "@mui/icons-material";
import InsightsBtn from "../Common/buttons/InsightsBtn";
import {useFetchParamsContext} from "../../context/useFetchParamsContext";


const AgreementRow: React.FC = (props: { agreement: Agreement, setLoading: any,
  setCheckVsActualId:any, agreementVsActual:Dictionary<string> }) => {
  const { agreement, setCheckVsActualId, agreementVsActual } = props;
  const [expanded, setExpanded] = useState(false);
  const [checkVsActual, setCheckVsActual] = useState(false);
  // const [params, setParams] = useState<PayrollInsights>({
  //   tab: "agreement",
  //   path: "payroll/verify-salary-based-agreement",
  //   parameters: {
  //     identity_number: agreement.identity_number
  //   }
  // })

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const internalKeys = ["id", "component_type", "is_deleted", "model"];

const handleCheckVsActual = () => {
    setCheckVsActual(true);
    setCheckVsActualId(agreement.identity_number);
  };

  const agreementDetails = Object.entries(agreement)
    .filter(([key]) => !internalKeys.includes(key)) // Filter out internal keys
    .map(([key, value]) => (
      <Stack key={key} direction="row" spacing={3} sx={{ paddingBottom: 1 }}>
        <Box width="30%">
          <Typography variant="body1" fontWeight={600}>
            {formatKey(key)}
          </Typography>
        </Box>
        <Box width="40%">
          <Typography variant="body1">{value}</Typography>
        </Box>
        <Box width="30%">
            <Typography variant="body1"
                        sx={{color: agreementVsActual[key] && !agreementVsActual[key]['same'] ? 'red' : 'black'  }}>
                {agreementVsActual[key] ? agreementVsActual[key]['last_salary'] : ''}
            </Typography>
        </Box>
      </Stack>
    ));

  return (
    <>
      <Box
        sx={{
          display: "block",
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          borderLeft: "5px solid green",
          marginBottom: 2,
          overflow: "hidden",
          boxShadow: 3,
          transition: "all 0.3s ease",
          "&:hover": { backgroundColor: "#e0e0e0" },
          cursor: "pointer",
        }}
        onClick={toggleExpand}
        padding={2}
      >
        <Box display={"inline-block"} sx={{ paddingRight: 2 }}>
          <Typography variant="body1">{agreement.employee_name}</Typography>
        </Box>
        <Box display={"inline-block"} sx={{ paddingRight: 2 }}>
          <Typography variant="body1">Upload: {agreement.creation_date}</Typography>
        </Box>
        <Box display={"inline-block"} sx={{ paddingRight: 2 }}>
          <Typography sx={{ fontWeight: "600" }} variant="body1">
            Details
          </Typography>
        </Box>
      </Box>
      {/* Expanded State */}
      <Collapse in={expanded}>
        <Box
          sx={{
            maxWidth: "700px",
            padding: 3,
            marginLeft: 3,
            backgroundColor: "#ffffff",
            boxShadow: 1,
            borderLeft: "5px solid #F2BA29",
          }}
        >
            <Box display={"flow-root"}>
              <div className="w-full flex justify-end items-center mb-5 gap-5">
                <Button
                  disabled={checkVsActual}
                  onClick={handleCheckVsActual}
                  sx={{
                    width: "178px",
                    height: "30px",
                    boxShadow: 6,
                    borderRadius: "10px",
                    // float:'right',
                    // marginBottom:2
                  }}
                >
                        <PublishedWithChanges sx={{ marginRight: 2 }} />
                  Check vs. Actual
                </Button>
                {/* <InsightsBtn employee={{}}/> */}
                </div>
            </Box>
            <Stack key="title" variant="body1" direction="row" spacing={3} sx={{ paddingBottom: 1 , fontWeight: "600", textDecoration:'underline'}}>
                <Box width="30%">Property</Box>
                <Box width="40%">Agreement</Box>
                <Box width="30%">Actual</Box>
              </Stack>
          {agreementDetails}
        </Box>
      </Collapse>
    </>
  );
};

// AgreementTab Component
const AgreementTab: React.FC = () => {
  const [refresh, setRefresh] = useState(false);
  const [checkVsActualId, setCheckVsActualId] = useState(-1);
  const { agreements, agreementVsActual, loading, setLoading, error } = useGetAgreements(refresh, checkVsActualId);
  // const [params, setParams] = useState<PayrollInsights>({
  //   tab: "agreement",
  //   path: "payroll/verify-salary-based-agreement",
  //   parameters: {
  //     // identity_number: agreement.identity_number
  //   }
  // })
  
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { user, email } = useUserContext();
  const { fetchParams } = useFetchParamsContext();
  const organization = fetchParams.company.org_id || "";

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isAgreement = true;
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setLoading(true); // Set loading to true before calling UploadFile
      try {
        await UploadFile(user, email, organization, file, isAgreement);
        setRefresh((prev) => !prev); // Trigger refresh to fetch new data
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };



  return (
    <Grid2 container spacing={2} direction="column">
      <Grid2 xs={8} xl={4}>
        <div className="flex gap-5">
        <Button
          onClick={handleUploadClick}
          sx={{
            width: "250px",
            height: "50px",
            boxShadow: 6,
            borderRadius: "10px",
          }}
        >
          <AddCircleOutlineIcon sx={{ marginRight: 3 }} />
          Upload New Agreement
        </Button>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          accept=".pdf"
          onChange={handleFileChange}
        />
        {/* <InsightsBtn params={params}/> */}
        </div>
        <Box
          sx={{
            marginLeft: "25px",
            marginTop: "7px",
            display: "inline-block",
            justifyContent: "center",
          }}
        >
          {loading ? <CircularProgress /> : ""}
          {error ? error : ""}
        </Box>
      </Grid2>

      {agreements.map((agreement, index) => (
        <Grid2 key={index} xs={4}>
          <AgreementRow agreement={agreement} setCheckVsActualId={setCheckVsActualId}
                        agreementVsActual={agreement.identity_number === checkVsActualId ? agreementVsActual : {}} />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default AgreementTab;
