import { useFetchParamsContext } from "../context/useFetchParamsContext";
import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { Agreement, Dictionary } from "../types/data";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useGetAgreements = (
  refresh: boolean,
  checkVsActualId: number
): {
  agreements: Agreement[];
  agreementVsActual: Dictionary<string>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>; // Expose `setLoading`
  error: string | null;
} => {
  const [agreements, setAgreements] = useState<Agreement[]>([]);
  const [agreementVsActual, setAgreementVsActual] = useState<{}>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUserContext();
  const { fetchParams } = useFetchParamsContext();

  const options = useMemo(
    () => ({
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${user?.credential}`,
      },
    }),
    [user?.credential]
  );

  const fetchAgreements = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = new URLSearchParams({
        organization: fetchParams.company.org_id || "",
      }).toString();
      const response = await fetch(
        `${API_BASE_URL}/llm/employment_agreement?${params}`,
        options
      );
      const data = await response.json();
      setAgreements(data);
    } catch (err: any) {
      setError(err.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [options, fetchParams.company]);

  const fetchAgreementVsActual = useCallback(async () => {
    setLoading(true);
    setError(null);
    const params = new URLSearchParams({
      identity_number: checkVsActualId.toString(),
      organization: fetchParams.company.org_id || "",
    }).toString();
    try {
      const response = await fetch(
        `${API_BASE_URL}/payroll/verify-salary-based-agreement?${params}`,
        options
      );
      const data = await response.json();
      setAgreementVsActual(data);
    } catch (err: any) {
      setError(err.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  }, [options, checkVsActualId, fetchParams.company]);

  useEffect(() => {
    fetchAgreements();
  }, [fetchAgreements, refresh, fetchParams.company]);

  useEffect(() => {
    if (checkVsActualId !== -1) {
      fetchAgreementVsActual();
    }
  }, [checkVsActualId, fetchParams.company]);

  return { agreements, agreementVsActual, loading, setLoading, error };
};
