import React from 'react'
import MarkDown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
    text: string
}

const Markdown = ({text}: Props) => {
  return (
    <MarkDown children={text} remarkPlugins={[remarkGfm]}/>
  )
}

export default Markdown