import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";

export interface HeaderProps {
  token: string;
  userEmail: string;
  chatId: string;
  handleClose: () => void;
  title: string;
}

export const Header: React.FC<HeaderProps> = ({
  handleClose,
  title,
}) => {
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  //this is temp solution, we should not use dom manipulation in react
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setIsShowDropdown]);


  return (
    <div className={styles["header-container"]}>
      <div className={styles["menu-container"]}>
        <div
          ref={dropdownRef}
          className={styles["dropdown"]}
          style={{ display: `${isShowDropdown ? "block" : "none"}` }}
        >
        </div>
      </div>
      <div className={styles["header-spacer"]} />
      <h3 className={styles["header-title"]}>{title}</h3>
      <div className={styles["header-spacer"]} />
      <button className={styles["header-close"]} onClick={handleClose}>
        X
      </button>
    </div>
  );
};
