import { MessageType } from "../../shared/types";
import { BouncingDotsLoader } from "../Loader/BouncingDotsLoader";
import styles from "./styles.module.css";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Explanation } from "./Explanation";
import { InitialMessage } from "./InitalMessage";
import { IS_LOADING } from "../../view/Chat";
import { useMemo } from "react";

export enum ImageTypeOptions {
  JPEG = "jpeg",
  JPG = "jpg",
  GIF = "gif",
  PGN = "png",
}

export interface MessageProps {
  message: MessageType;
}

export const Message: React.FC<MessageProps> = ({ message }) => {
  const demoMsg = null;
  if (!message.content && !message.attachments) {
    return <InitialMessage />
  }
  else if (message.content === IS_LOADING) {
    return <div className={`${styles.message} ${styles["assistant-message"]}`}>
      <BouncingDotsLoader />
    </div>
  }
  if (!message.content){
    message.content = '';
  }
  // if (!message.content && !message.attachments) {
  //   return <InitialMessage />
  // }
  // else if (message.content === IS_LOADING) {
  //   return <div className={`${styles.message} ${styles["assistant-message"]}`}>
  //     <BouncingDotsLoader />
  //   </div>
  // }
  // if (!message.content){
  //   message.content = '';
  // }


    // const htmlMsg = useMemo(() => {
    //   return <>

    //   </>
    // }, [message])
  
  return (
    <div
      className={`${styles.message} ${message.role === "user"
        ? styles["user-message"]
        : styles["assistant-message"]
        }`}
    >
      <div className="flex items-center gap-1">
      <Explanation explanation={message.explanation} >
            {message.content}
          </Explanation>
      </div>
      {message.attachments &&
        message.attachments.map(({ id, name, url }) => {
          return (
            <img key={id} src={url} alt={name} width={250} height={250} />
          );
        })}
    </div>
  );
};
