import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Grid2,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Employee, PayrollInsights } from "@/types/data";
import { useGetIrregularGross } from "../../hooks/useGetIrregularGross";
import MonthYearSelect from "./MonthYearSelect";
import { formatKey, formatNumber } from "../../utils/common";
import InsightsBtn from "../Common/buttons/InsightsBtn";
import { useFetchParamsContext } from "../../context/useFetchParamsContext";

function Row(props: {
  employee: Employee;
  employeeInsight: Record<any, any>[] | undefined;
}) {
  const { employee, employeeInsight } = props;
  const [open, setOpen] = useState(false);

  const insight =
    typeof employeeInsight !== "undefined" ? employeeInsight[0] : undefined;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{employee["Employee name"]}</TableCell>
        <TableCell align="left">
          {formatNumber(employee["Gross Payroll Current Month"])}
        </TableCell>
        <TableCell align="left">
          {formatNumber(employee["Gross Payroll Previous Month"])}
        </TableCell>
        <TableCell align="left">{formatNumber(employee["Diff"])}</TableCell>
        {typeof insight !== "undefined" ? (
          <TableCell align="left">
            <InsightsBtn employee={insight} />
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom>
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Gross Payroll Current Month</TableCell>
                    <TableCell>Gross Payroll Previous Month</TableCell>
                    <TableCell>Diff</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employee.components_break_down.map((component) => {
                    const cellStyle = {
                      fontWeight:
                        component.component_name === "Total" ? 600 : "normal",
                    };
                    return (
                      <TableRow key={component.component_name}>
                        <TableCell sx={cellStyle}>
                          {formatKey(component.component_name)}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {formatNumber(
                            component["Gross Payroll Current Month"]
                          )}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {formatNumber(
                            component["Gross Payroll Previous Month"]
                          )}
                        </TableCell>
                        <TableCell sx={cellStyle}>
                          {formatNumber(component.Diff)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function IrregularGrossTable() {
  const [employeeInsightsArr, setEmployeeInsightsArr] = useState<[] | null>();
  const [params, setParams] = useState<PayrollInsights>({
    tab: "irregular",
    path: "payroll/irregular-gross-payment",
    parameters: {
      target_month: "2024/09",
      previous_month: "2024/08",
    },
  });

  // Pass the selected months to the custom hook
  const { irregularGross, loading, error } = useGetIrregularGross({
    targetMonth: params?.parameters?.target_month,
    previousMonth: params?.parameters?.previous_month,
  });

  const employees = irregularGross?.employees ?? [];

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Sorting state
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = useState<keyof Employee>("Diff");

  const handleRequestSort = (property: keyof Employee) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortEmployees = (array: Employee[]) => {
    return array.slice().sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      const aNum = parseFloat(aValue as string);
      const bNum = parseFloat(bValue as string);

      if (!isNaN(aNum) && !isNaN(bNum)) {
        return order === "asc" ? aNum - bNum : bNum - aNum;
      }

      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayedEmployees = sortEmployees(employees).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  function checkForEmployeeInsight(
    param: Array<Record<any, any>> | null | undefined,
    employee: Record<any, any>
  ) {
    if (param) {
      const filterdArr = param.filter(
        (el) => el.identifier === employee["Employee Number"]
      );
      return filterdArr;
    }
  }

  return (
    <>
      <Grid2 display={"flex"}>
        <div className="flex gap-5 my-3">
          <MonthYearSelect
            targetMonth={params?.parameters?.target_month}
            previousMonth={params?.parameters?.previous_month}
            onTargetMonthChange={(value) =>
              setParams((prev) => {
                return {
                  ...prev,
                  parameters: {
                    ...prev.parameters,
                    target_month: value,
                  },
                };
              })
            }
            onPreviousMonthChange={(value) =>
              setParams((prev) => {
                return {
                  ...prev,
                  parameters: {
                    ...prev.parameters,
                    previous_month: value,
                  },
                };
              })
            }
          />
          <InsightsBtn
            params={params}
            value={(val) => setEmployeeInsightsArr(val)}
          />
        </div>
        <Box
          sx={{
            marginLeft: "25px",
            marginTop: "7px",
            display: "inline-block",
            justifyContent: "center",
          }}
        >
          {loading ? <CircularProgress /> : ""}
          {error ? error : ""}
        </Box>
      </Grid2>

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <TableSortLabel
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                  active={orderBy === "Employee name"}
                  direction={order}
                  onClick={() => handleRequestSort("Employee name")}
                >
                  Employee name
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                  active={orderBy === "Gross Payroll Current Month"}
                  direction={order}
                  onClick={() =>
                    handleRequestSort("Gross Payroll Current Month")
                  }
                >
                  Gross Payroll Current Month
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                  active={orderBy === "Gross Payroll Previous Month"}
                  direction={order}
                  onClick={() =>
                    handleRequestSort("Gross Payroll Previous Month")
                  }
                >
                  Gross Payroll Previous Month
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  sx={{ fontWeight: "bold", fontSize: "14px" }}
                  active={orderBy === "Diff"}
                  direction={order}
                  onClick={() => handleRequestSort("Diff")}
                >
                  Diff
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedEmployees.map((employee) => (
              <Row
                key={employee["Employee name"]}
                employee={employee}
                employeeInsight={checkForEmployeeInsight(
                  employeeInsightsArr,
                  employee
                )}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={employees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
