import { useFetchParamsContext } from "../../context/useFetchParamsContext";
import { useUserContext } from "../../context/user-context";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import React, { useEffect, useState } from "react";

type Props = {
  currencies: Array<string>;
};

const CurrencySelect = ({ currencies }: Props) => {
  const { fetchParams, changeFetchParams } = useFetchParamsContext();

  function handleChange(event: SelectChangeEvent<string>) {
    const selectedCurrency = event.target.value;
    changeFetchParams({ currency: selectedCurrency });
    // setCurrency(selectedCurrency);
  }

  return (
    <div className="currency-select min-w-[120px] max-w-[150px] ml-2">
      <FormControl fullWidth>
        <InputLabel variant="filled" id="navbar-curreny-select">
          Currency
        </InputLabel>
        <Select
          labelId="navbar-curreny-select"
          value={fetchParams.currency}
          label={fetchParams.currency}
          sx={{ borderRadius: "10px" }}
          onChange={handleChange}
        >
          <MenuItem disabled value="">
            Select Currency
          </MenuItem>
          {currencies.map((currency) => {
            return (
              <MenuItem value={currency} key={currency}>
                {currency}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CurrencySelect;
