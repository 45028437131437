import React, { createContext, useContext, useState, ReactNode } from 'react';

// Create the Context
interface SelectedTabContextType {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const SelectedTabContext = createContext<SelectedTabContextType | undefined>(undefined);

// Custom hook to use the context
export const useSelectedTab = () => {
  const context = useContext(SelectedTabContext);
  if (!context) {
    throw new Error('useSelectedTab must be used within a SelectedTabProvider');
  }
  return context;
};

// Provider component
export const SelectedTabProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState<string>('dashboard');
  return (
      <SelectedTabContext.Provider value={{ selectedTab, setSelectedTab }}>
        {children}
      </SelectedTabContext.Provider>
  );
};
