import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { Organization } from "@/types/data";
import { useFetchParamsContext } from "../../context/useFetchParamsContext";

interface OrganizationSelectProps {
  organizations: Organization[];
}

const OrganizationSelect: React.FC<OrganizationSelectProps> = ({
  organizations,
}) => {
  // const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const { fetchParams, changeFetchParams } = useFetchParamsContext();

  // useEffect(() => {
  //   if (organizations.length > 0) {
  //     setSelectedOrg(organizations[0]);
  //   }
  // }, [organizations]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedOrgId = event.target.value as string;
    const org = organizations.find((o) => o.org_id === selectedOrgId) || null;
    org && changeFetchParams({ company: org }); // Checks if org is not null then updates.
    // setSelectedOrg(org);
  };

  return (
    <FormControl fullWidth sx={{ marginLeft: 5, maxWidth: 250 }}>
      <InputLabel variant="filled" id="organization-select-label">
        Company
      </InputLabel>
      <Select
        sx={{ borderRadius: "10px" }}
        value={fetchParams?.company.org_id}
        label={fetchParams?.company.org_id}
        onChange={handleChange}
        // displayEmpty
      >
        <MenuItem value="" disabled>
          Select a Company
        </MenuItem>
        {organizations.map((org) => (
          <MenuItem key={org.org_id} value={org.org_id}>
            {org.org_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrganizationSelect;
