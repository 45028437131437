import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableSortLabel from "@mui/material/TableSortLabel";
import { EmployeeInOut, PayrollInsights } from "@/types/data";
import { formatKey, formatNumber } from "../../utils/common";
import InsightsBtn from "../Common/buttons/InsightsBtn";
import { useState } from "react";
import { useGetInputOutput } from "../../hooks/useGetInputOutput";

function Row(props: {
  employee: EmployeeInOut;
  employeeInsight: Record<any, any>[] | undefined;
}) {
  const { employee, employeeInsight } = props;
  const [open, setOpen] = React.useState(false);

  const insight =
    typeof employeeInsight !== "undefined" ? employeeInsight[0] : undefined;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{employee["Employee Number"]}</TableCell>
        <TableCell align="left">{employee["Employee name"]}</TableCell>
        <TableCell align="left">
          {formatNumber(employee["Input Total Gross"])}
        </TableCell>
        <TableCell align="left">
          {formatNumber(employee["Output Total Gross"])}
        </TableCell>
        <TableCell align="left">
          {formatNumber(employee["Gross Payroll Total Differences"])}
        </TableCell>
        {typeof insight !== "undefined" ? (
          <TableCell align="left">
            <InsightsBtn employee={insight} />
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Input</TableCell>
                    <TableCell align="left">Output</TableCell>
                    <TableCell>Difference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employee["Component Differences"].map((component) => {
                    const cellStyle = {
                      fontWeight:
                        component.component_name === "Total" ? 600 : "normal",
                    };
                    return (
                      <TableRow key={component.component_name}>
                        <TableCell align="left" sx={cellStyle}>
                          {formatKey(component.component_name)}
                        </TableCell>
                        <TableCell align="left" sx={cellStyle}>
                          {formatNumber(component["Input"])}
                        </TableCell>
                        <TableCell align="left" sx={cellStyle}>
                          {formatNumber(component["Output"])}
                        </TableCell>
                        <TableCell align="left" sx={cellStyle}>
                          {formatNumber(component.Difference)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function InputOutputTable() {
  const inputOutput = useGetInputOutput();
  const employees = inputOutput?.inputOutput?.employees ?? [];
  const [params, setParams] = useState<PayrollInsights>({
    tab: "input vs output",
    path: "payroll/input-vs-output",
    parameters: {
      target_month: "2024/09",
    },
  });
  const [employeeInsightsArr, setEmployeeInsightsArr] = useState<[] | null>();

  // Sorting state
  const [order, setOrder] = React.useState<"asc" | "desc">("desc");
  const [orderBy, setOrderBy] = React.useState<keyof EmployeeInOut>(
    "Gross Payroll Total Differences"
  );

  // Pagination state
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleSort = (property: keyof EmployeeInOut) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const stableSort = (
    array: EmployeeInOut[],
    comparator: (a: EmployeeInOut, b: EmployeeInOut) => number
  ) => {
    const stabilizedArray = array.map(
      (el, index) => [el, index] as [EmployeeInOut, number]
    );
    stabilizedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  const getComparator = (
    order: "asc" | "desc",
    orderBy: keyof EmployeeInOut
  ) => {
    return order === "desc"
      ? (a: EmployeeInOut, b: EmployeeInOut) =>
          descendingComparator(a, b, orderBy)
      : (a: EmployeeInOut, b: EmployeeInOut) =>
          -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (
    a: EmployeeInOut,
    b: EmployeeInOut,
    orderBy: keyof EmployeeInOut
  ) => {
    let valueA, valueB;
    valueA = parseFloat(a[orderBy] as string);
    valueB = parseFloat(b[orderBy] as string);
    if (orderBy === "Employee name") {
      valueA = a[orderBy];
      valueB = b[orderBy];
    }
    if (valueB < valueA) return -1;
    if (valueB > valueA) return 1;
    return 0;
  };

  const sortedEmployees = stableSort(employees, getComparator(order, orderBy));
  const displayedEmployees = sortedEmployees.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  function checkForEmployeeInsight(
    param: Array<Record<any, any>> | null | undefined,
    employee: Record<any, any>
  ) {
    if (param) {
      const filterdArr = param.filter(
        (el) => el.identifier === employee["Employee Number"]
      );
      return filterdArr;
    }
  }

  return (
    <>
      <div className="max-w-28 mb-2">
        <InsightsBtn
          params={params}
          value={(val) => setEmployeeInsightsArr(val)}
        />
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "14px" }}
                sortDirection={orderBy === "Employee Number" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Employee Number"}
                  direction={orderBy === "Employee Number" ? order : "asc"}
                  onClick={() => handleSort("Employee Number")}
                >
                  Employee Number
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "14px" }}
                sortDirection={orderBy === "Employee name" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Employee name"}
                  direction={orderBy === "Employee name" ? order : "asc"}
                  onClick={() => handleSort("Employee name")}
                >
                  Employee Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "14px" }}
                sortDirection={orderBy === "Input Total Gross" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Input Total Gross"}
                  direction={orderBy === "Input Total Gross" ? order : "asc"}
                  onClick={() => handleSort("Input Total Gross")}
                >
                  Input
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "14px" }}
                sortDirection={orderBy === "Output Total Gross" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "Output Total Gross"}
                  direction={orderBy === "Output Total Gross" ? order : "asc"}
                  onClick={() => handleSort("Output Total Gross")}
                >
                  Output
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "14px" }}
                sortDirection={
                  orderBy === "Gross Payroll Total Differences" ? order : false
                }
              >
                <TableSortLabel
                  active={orderBy === "Gross Payroll Total Differences"}
                  direction={
                    orderBy === "Gross Payroll Total Differences"
                      ? order
                      : "asc"
                  }
                  onClick={() => handleSort("Gross Payroll Total Differences")}
                >
                  Gross Payroll Total Differences
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedEmployees.map((employee) => (
              <Row
                key={employee["Employee Number"]}
                employee={employee}
                employeeInsight={checkForEmployeeInsight(
                  employeeInsightsArr,
                  employee
                )}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={employees.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
