import React, { useEffect, useRef, useCallback, useMemo } from "react";
import { Message } from "../components/Message";
import { Input } from "../components/Input";
import { ChatState, MessageType } from "../shared/types";
import { Header } from "../components/Header";
import styles from "./styles.module.css";
import { useChatReducer } from "../reducer";

export const IS_LOADING = "Loading";

export interface ChatProps {
  token: string;
  handleClose: () => void;
  title?: string;
  chatId?: string;
  userEmail?: string;
  selectedTab?: string;
}

export const Chat: React.FC<ChatProps> = ({
  token,
  handleClose = () => { },
  title = "Chat",
  chatId = "",
  userEmail = "",
  selectedTab = "",
}) => {
  const initialState: ChatState = {
    messages: [{ role: "assistant", content: null }],
    chat: chatId || "",
    user: userEmail || "",
  };
  const [state, dispatch] = useChatReducer(initialState);


  const { user, chat, messages } = state;
  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesRef.current?.lastElementChild?.scrollIntoView();
  }, [messages]);

  const handleInputChange = useCallback((newMessages: MessageType[]) => {
    dispatch({ type: "SET_MESSAGES", payload: newMessages });
  }, []);

  const renderedMessages = useMemo(() => {
    if (!messages) {
      return null;
    } else {
      return messages.map((message, index) => (
        <Message key={index} message={message} />
      ));
    }
  }, [messages]);

  return (
    <>
      <Header
        token={token}
        userEmail={user}
        chatId={chat}
        handleClose={handleClose}
        title={title}
      />
      <div className={styles["app-container"]}>
        <div className={styles["chat-container"]}>
          <div className={styles["chat-messages"]} ref={messagesRef}>
            {renderedMessages}
          </div>
          <Input
            token={token}
            selectedTab={selectedTab}
            userEmail={user}
            chatId={chat}
            messages={messages}
            setMessages={handleInputChange}
          />
        </div>
      </div>
    </>
  );
};
