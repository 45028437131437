import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

interface NavProps  {
    setSelectedTab?: React.Dispatch<React.SetStateAction<string>>,
    selectedTab: string,

}
interface BtnProps  {
    title: string,
    selected?: boolean,
    onClick?: (event: React.MouseEvent<HTMLLIElement>) => void
}



const PayrollNav = ({ setSelectedTab, selectedTab}: NavProps) => {
    const {t} = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLLIElement> ) => {
        const workspaceString = event.currentTarget.innerHTML.toLowerCase() 
                if(event.currentTarget && setSelectedTab )
                     setSelectedTab(workspaceString);     
     }

  return (
    <div className='w-full my-2'>
    <ul className={`w-full flex items-center px-20 gap-8 py-2`}>
        <PayrollNavButton onClick={handleClick} title={t('payroll.dashboard')} selected={t('payroll.dashboard').toLowerCase() === selectedTab}/>
        <PayrollNavButton onClick={handleClick} title={t('payroll.inputOutput')} selected={t('payroll.inputOutput').toLowerCase() === selectedTab}/>
        <PayrollNavButton onClick={handleClick} title={t('payroll.irregular')} selected={t('payroll.irregular').toLowerCase() === selectedTab}/>
        <PayrollNavButton onClick={handleClick} title={t('payroll.agreements')} selected={t('payroll.agreements').toLowerCase() === selectedTab}/>
    </ul>
</div>
  )
}





const PayrollNavButton = ({title, selected, onClick}: BtnProps) => {
    return <li onClick={(e) => onClick && onClick(e)} className={`px-3 py-2 rounded transition-all cursor-pointer ${selected && "bg-[#0d838c] text-white"}`}>{title}</li>
} 

export default PayrollNav