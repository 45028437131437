import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useUserContext } from "../context/user-context";
import { API_BASE_URL } from "../types/constanst";
import { PayrollDashboad } from "../types/data";
import { useFetchParamsContext } from "../context/useFetchParamsContext";

const defaultPayrollDashboad: PayrollDashboad = {
  payroll_overview: {
    total_employer_cost: 0,
    net_payroll: 0,
    income_taxes: 0,
    national_insurance: 0,
    social_benefits: 0,
  },
  payroll_cost: [],
  head_count: [],
};

// Helper function to transform data
function transformData(data: any[]): any[] {
  return data.map((item) => {
    const { break_down, ...rest } = item;
    return {
      ...rest,
      ...break_down, // Merge `break_down` fields
    };
  });
}

// Custom hook
export const useGetPayrollDashboad = (
  selectedMonth: number,
  selectedYear: number
): {
  payrollDashboard: PayrollDashboad;
  loading: boolean;
  error: string | null;
} => {
  const [payrollDashboard, setPayrollDashboard] = useState<PayrollDashboad>(
    defaultPayrollDashboad
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { fetchParams } = useFetchParamsContext();
  const { user } = useUserContext();

  const options = useMemo(() => {
    return {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${user?.credential}`,
      },
    };
  }, [user?.credential]);

  const fetchPayrollDashboard = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = new URLSearchParams({
        year: selectedYear.toString(),
        month: selectedMonth.toString(),
        currency: fetchParams.currency,
        organization: fetchParams.company.org_id || "",
      }).toString();

      const response = await fetch(
        `${API_BASE_URL}/payroll/dashboard?${params}`,
        options
      );

      const data = await response.json();

      if (!response.ok) {
        let message = "Failed to fetch payroll dashboard data";
        if (data?.detail) {
          message = data.detail;
        }
        throw new Error(message);
      }

      const payrollCost = transformData(data.payroll_cost);
      const headCount = transformData(data.head_count);

      const transformedData: PayrollDashboad = {
        payroll_cost: payrollCost,
        head_count: headCount,
        payroll_overview: data.payroll_overview,
      };

      setPayrollDashboard(transformedData);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Unknown error");
      setPayrollDashboard(defaultPayrollDashboad);
    } finally {
      setLoading(false);
    }
  }, [selectedMonth, selectedYear, options, fetchParams]);

  const previousParamsRef = useRef<{
    selectedMonth: number;
    selectedYear: number;
    currency: string;
    company: string;
  } | null>(null);

  useEffect(() => {
    const shouldFetch =
      !previousParamsRef.current || // First time fetch
      previousParamsRef.current.selectedMonth !== selectedMonth || // Selected month changed
      previousParamsRef.current.selectedYear !== selectedYear || // Selected year changed
      previousParamsRef.current.currency !== fetchParams.currency || // Selected Currency changed
      previousParamsRef.current.company !== fetchParams.company.org_id; // Selected Company changed

    if (shouldFetch) {
      fetchPayrollDashboard();

      // Update the previous parameters after fetching
      previousParamsRef.current = {
        selectedMonth,
        selectedYear,
        currency: fetchParams.currency,
        company: fetchParams.company.org_id || "",
      };
    }
  }, [selectedMonth, selectedYear, fetchPayrollDashboard, fetchParams]);

  return { payrollDashboard, loading, error };
};
