import React from "react";
import { ParentContainer } from "./Chat.styels";
import { useUserContext } from "../../context/user-context";
import {useSelectedTab} from '../../context/SelectedTabContext';
// import { Chat } from "@/chat/view/Chat";
import {Chat} from '../../chat/view/Chat';

export const ChatComponent = () => {
  const { user, email } = useUserContext();
  const { selectedTab } = useSelectedTab();

  return (
    <ParentContainer>
      <Chat
        title={"Start a conversation"}
        token={user?.credential ?? ""}
        selectedTab={selectedTab}
        handleClose={() => {
          window.location.reload();
        }}
        userEmail={email}
        chatId={undefined}
      />
    </ParentContainer>

  );
};
