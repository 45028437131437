import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { ChatComponent } from "../components/Chat/Chat";
import { StyledIconButton } from "../components/navbar/Navbar.styles";
import * as React from "react";
import {PayrollTabs} from "../components/headers/payroll-header";
import {SelectedTabProvider} from "../context/SelectedTabContext";

const PayrollHeader = styled(Paper)(({ theme }) => ({
  elevation: 0,
  backgroundColor: '#fff',
  width: '100%',
  height: '4vh',
  color: "#0d838c",
  borderRadius: 0,
  borderBottom: "2px solid #0d838c",
  ...theme.typography.body2,
  textAlign: 'center',
}));

export const Payroll: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
  <SelectedTabProvider>
   <section className='w-full h-full flex pt-4'>
        <div className={`${isDrawerOpen ? 'w-[650px] flex-grow-1' : 'translate-x-[-100%] w-[0px]'} z-[1] overflow-hidden transition-all duration-500 ease-in-out`}>
        <PayrollHeader component="div">
          <Typography variant="h4" className="header-title">Let's Talk finance</Typography>
        </PayrollHeader>
            <ChatComponent /> 
        </div>

        {/* Main Content Area */}
        <div className='payroll_workspace flex-grow'
        >
          <StyledIconButton
            onClick={toggleDrawer}
            sx={{
              position: "fixed",
              top: "70px",
              left: "21px",
              zIndex: 11111,
            }}
          >
            <OpenInFullIcon />
          </StyledIconButton>

          <PayrollHeader component="div">
            <Typography variant="h4" className="header-title">Payroll Workspace</Typography>
          </PayrollHeader>
         <PayrollTabs/>
        </div>
      </section>
  </SelectedTabProvider>
  );
};
