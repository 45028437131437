import { Organization } from "@/types/data";
import { useAuth } from "./AuthContext";
import React, { useMemo, useState, createContext, useContext } from "react";

interface FetchParamsContextType {
  fetchParams: FetchParams;
  changeFetchParams: ({ currency, company }: Partial<FetchParams>) => void;
}

interface FetchParams {
  currency: string;
  company: Partial<Organization>;
}

const FetchParamsContext = createContext<FetchParamsContextType>({
  fetchParams: {
    currency: "",
    company: {
      org_id: "",
      org_name: "",
    },
  },
  changeFetchParams: () => {},
});

export const FetchParamsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [fetchParams, setFetchParams] = useState<FetchParams>({
    currency: "",
    company: {
      org_id: "",
      org_name: "",
    },
  });
  const { userDetails }: any = useAuth();

  function changeFetchParams({ currency, company }: Partial<FetchParams>) {
    const newCurrencyValue = currency ? currency : fetchParams.currency;
    const newCompanyValue = company ? company : fetchParams.company;
    setFetchParams({ currency: newCurrencyValue, company: newCompanyValue });
  }

  useMemo(() => {
    if (
      userDetails &&
      (userDetails.primary_currency || userDetails.organization_id)
    )
      setFetchParams((prev) => {
        return {
          ...prev,
          ["currency"]: userDetails.primary_currency || "",
          ["company"]: {
            org_id: userDetails.organization_id || "",
            org_name: userDetails.managed_orgs.find(
              (org: any) => org.org_id == userDetails.organization_id
            ),
          },
        };
      });
  }, [userDetails]);

  return (
    <FetchParamsContext.Provider value={{ fetchParams, changeFetchParams }}>
      {children}
    </FetchParamsContext.Provider>
  );
};

export const useFetchParamsContext = () => useContext(FetchParamsContext);
