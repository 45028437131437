
import styles from "./styles.module.css";
import { useAuth } from "../../../context/AuthContext";

export const InitialMessage = () => {
    const {userDetails} = useAuth();
    
    const initialMessageText = `Hi ${userDetails?.name}, I'm your personal assistant. How can I help you today?`;
    
    return (
        <div className={`${styles.message} ${styles["assistant-message"]}`}>
            <>{initialMessageText}</>
        </div>
    );
};