import './BouncingDotsLoader.css';

export const BouncingDotsLoader = () => {
    return (
        <>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};