import React, { useEffect, useMemo, useState } from "react";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import useGetInsights from "../../../hooks/useGetInsights";
import { createPortal } from "react-dom";
import Modal from "../Modals/Modal";
import Markdown from "../../../utils/Markdown";
import { CircularProgress, Tooltip, Zoom } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { EmployeeInOut, PayrollInsights } from "@/types/data";

type Props = {
  params?: PayrollInsights;
  value?: (value: any) => any;
  employee?: Record<any, any>;
};

const InsightsBtn = ({ params, employee, value }: Props) => {
  const data = [
    {
      method: "GET",
      ...params,
    },
  ];
  const { insights, setTrigger, trigger } = useGetInsights(
    data,
    employee ? true : false
  );
  const [activeModal, setActiveModal] = useState(false);

  //setTrigger from useGetInsights to trigger a request to the server incase the data has changed.
  function handleClickInsights(): void {
    if (!employee) {
      setTrigger(true);
    }
    setActiveModal(true);
  }

  // Gets the string from the object returned from the server.
  const insightText = useMemo(() => {
    if (employee) {
      return employee.insight;
    }
    if (insights) {
      const array: Array<any> = Object.values(insights);
      if (typeof array[0] === "object") return array[0].main_insight;
    }
  }, [insights, employee]);

  if (insights && value) {
    const arr: Array<Record<any, any>> = Object.values(insights);

    if (arr.length > 0) {
      value(arr[0]["top_10_diff_employees_insights"]);
    }
  }

  if (employee) {
    return (
      <>
        <button className="transition-all">
          <Tooltip
            title={employee.insight}
            slots={{ transition: Zoom }}
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: "gray",
                },
              },
            }}
          >
            <EmojiObjectsIcon sx={{ fill: "orange" }} />
          </Tooltip>
        </button>
      </>
    );
  }

  return (
    <>
      <li
        onClick={() => handleClickInsights()}
        className="flex shadow-lg gap-2 items-center justify-center font-semibold border p-2 rounded-xl cursor-pointer hover:translate-y-[-2px] transition-all"
      >
        <EmojiObjectsIcon sx={{ fill: "orange" }} />
        <p>Insights</p>
      </li>
      {activeModal &&
        createPortal(
          <Modal>
            <div
              onClick={() => setActiveModal(false)}
              className="absolute top-5 right-5 cursor-pointer"
            >
              <CloseIcon />
            </div>
            {trigger ? (
              <div className="h-96 w-96 flex justify-center items-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                <h2 className="text-2xl text-center font-semibold underline tracking-wider mb-2">
                  Insights
                </h2>
                <Markdown text={insightText} />
              </>
            )}
          </Modal>,
          document.body
        )}
    </>
  );
};

export default InsightsBtn;
